// @flow
import { fork, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import { callApi } from './api';
import { watchRouter } from './watchRouter';

import { getAccessToken, isAccessTokenExpired } from 'shared/utils/authCookie';
import checkoutSaga from 'redux/modules/checkout/checkoutSagas';
import { setIsAuthed, getUser } from 'redux/modules/user/userActionCreators';
import { addCookiesOnCreateUserSuccess } from 'redux/modules/user/userSagas';

// Only do Segment tracknig in prod!

export default function* rootSaga(): Saga {
  // initialize sagas
  yield fork(callApi);
  yield fork(watchRouter);
  yield fork(checkoutSaga);
  yield fork(addCookiesOnCreateUserSuccess);

  const accessToken = getAccessToken();
  const isExpired = isAccessTokenExpired();

  if (!!accessToken && !isExpired) {
    yield put(setIsAuthed());
    yield put(getUser());
  }

  console.log('🙌 root saga initialized');
}
